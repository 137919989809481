<template>
  <div>
    <div class="d-flex justify-content-center" v-if="userData === null">
      <b-spinner label="Loading..." variant="primary" class="mr-1"></b-spinner>
      <strong>Loading...</strong>
    </div>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user
      </h4>
      <div class="alert-body">
        No user found with this username.
      </div>
    </b-alert>

    <template v-if="userData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="8"
          lg="8"
          md="7"
        >
          <user-view-user-info-card :user-data="userData" />
          <div v-if="userData.type === 'Seeker'">
            <user-view-idea :user-data="userData" />
          </div>
          <div v-if="userData.reviewCount > 0">
            <user-view-reviews-list :reviews="reviews" />
          </div>
        </b-col>
        <b-col
          cols="12"
          xl="4"
          lg="4"
          md="5"
        >
          <user-view-user-plan-card :user-data="userData" />
        </b-col>
      </b-row>
    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink, BSpinner,
} from 'bootstrap-vue'
import userStoreModule from '../userStoreModule'
import UserViewReviewsList from './UserViewReviewsList.vue'
import UserViewIdea from './UserViewIdea.vue'
import UserViewUserInfoCard from './UserViewUserInfoCard.vue'
import UserViewUserPlanCard from './UserViewUserPlanCard.vue'
import UserViewUserTimelineCard from './UserViewUserTimelineCard.vue'
import UserViewUserPermissionsCard from './UserViewUserPermissionsCard.vue'
import { getFirestore, getDocs, collection, getDoc, doc} from 'firebase/firestore'
import { getAuth } from "firebase/auth"

const db = getFirestore()
const auth = getAuth()

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BSpinner,

    // Local Components
    UserViewUserInfoCard,
    UserViewUserPlanCard,
    UserViewUserTimelineCard,
    UserViewUserPermissionsCard,
    UserViewIdea,
    UserViewReviewsList,
  },
  data() {
   return {
      username: null,
      userData: null,
      title: undefined,
      description: undefined,
      url: undefined,
      image: undefined,
      reviews: [],
      error:""
   }
  },
  metaInfo() {
    return {
      title: this.title,
      /* Doesnt work
      meta: [
        { name: 'description', content: this.description },
        { property: 'og:title', content: this.title },
        { property: 'og:site_name', content: 'TEST' },
        { property: 'og:description', content: this.description },
        { property: 'og:type', content: 'profile'},
        { property: 'og:url', content: this.url },
        { property: 'og:image', content: this.image }
      ]*/
    }
  },
  //insert the following code for vue-meta to work
  created: async function() {

    // Get the username from the route if not get the current profile
    if(router.currentRoute.params && router.currentRoute.params.id) this.username = router.currentRoute.params.id;
    else this.username = (auth && auth.currentUser)?auth.currentUser.displayName:null

    // Check the username
    if(!this.username) return;

    if(router.currentRoute.params && router.currentRoute.params.uid){
      this.userData = router.currentRoute.params
      this.getUserReviews(this.username)
    }else{
      this.getUserProfile(this.username)
      this.getUserReviews(this.username)
    }
  },
  methods:{
    async getUserProfile(username) {
      const profileRes = await getDoc(doc(db, "profiles", username))
      if(!profileRes.exists()) return
      this.userData = profileRes.data()
      this.userData.id = profileRes.id
    },
    async getUserReviews(username) {
      const snapshot = await getDocs(collection(db, "profiles", username, "reviews"))
      snapshot.forEach(doc => {
        let review = doc.data()
        review.id = doc.id
        review.date = doc.data().time.toDate()
        this.reviews.push(review)
      })
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
  },
}
</script>
