<template>
  <b-card>

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="12"
        class="d-flex justify-content-between flex-column"
      >
        <div class="badge badge-light-primary float-right" v-if="userData.linkedinID"><b-img style="height:30px;" :src="require('@/assets/images/misc/linkedinvalidated.png')"></b-img></div>
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start pt-1">
          <b-avatar
            :src="userData.image"
            :text="avatarText(userData.fullName)"
            :variant="`light-primary`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4>
                {{ userData.fullName }}
              </h4>
              <b-badge variant="primary">
                <feather-icon
                  icon="StarIcon"
                  class="mr-25"
                />
                <span>{{ userData.industry }}</span>
              </b-badge>
              <div class="mt-1">
                <span
                  class="badge badge-light-primary badge-skills"
                  v-for="skill in userData.skills"
                  :key="skill"
                  :value="skill"> {{ skill }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <!-- Tagline-->
        <div v-if="userData.tagline" class="d-flex flex-column ml-1">
          <div class="row">
            <div class="mt-1">
              <i>{{ userData.tagline }}</i>
            </div>
          </div>
        </div>

        <!-- Bio-->
       <div v-if="userData.bio" class="d-flex flex-column ml-1">
          <div class="row">
            <div class="mt-1">
              {{ userData.bio }}
            </div>
          </div>
        </div>

        <!-- Interests-->
        <div v-if="userData.pSkills" class="d-flex flex-column ml-1">
          <div class="row">
            <div class="mt-1">
              <span class="user-name font-weight-bolder">Interests </span>
              <span
                  class="badge badge-light-primary badge-skills"
                  v-for="pSkill in userData.pSkills"
                  :key="pSkill"
                  :value="pSkill"> {{ pSkill }}
                </span>
            </div>
          </div>
        </div>

        <!-- Products-->
        <div v-if="userData.pSkills" class="d-flex flex-column ml-1">
          <div class="row">
            <div class="mt-1">
              <span class="user-name font-weight-bolder">Products / Ideas </span>
              <b-link
                v-for="product in userData.products"
                :key="product.id"
                :value="product.id"
                class=""
                :to="{ name: 'pages-ideas-detail', params: product }">
                  | {{ product.productName }}
                </b-link>
            </div>
          </div>
        </div>
      </b-col>

        <!-- User Stats -->
        <div class="container mt-2 ml-2">
          <div class="row">
            <div class="col-sm d-flex align-items-center">
              <b-avatar
                variant="light-warning"
                rounded
              >
                <feather-icon
                  icon="StarIcon"
                  size="18"
                />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">
                  {{userData.reviewCount}}
                </h5>
                <small>Reviews</small>
              </div>
            </div>
            <div class="col-sm d-flex align-items-center">
              <b-avatar
                variant="light-success"
                rounded
              >
                <feather-icon
                  icon="MessageSquareIcon"
                  size="18"
                />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">
                  {{userData.responses}}
                </h5>
                <small>Responses</small>
              </div>
            </div>
            <div class="col-sm d-flex align-items-center">
              <b-avatar
                variant="light-primary"
                rounded
              >
                <feather-icon
                  icon="UsersIcon"
                  size="18"
                />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">
                  {{userData.contacts}}
                </h5>
                <small>Contacts</small>
              </div>
            </div>
          </div>
        </div>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BImg, BBadge, BLink
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import useUsersList from '../users-list/useUsersList'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BImg, BBadge, BLink
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { resolveUserRoleVariant, resolveUserTypeVariant } = useUsersList()
    return {
      avatarText,
      resolveUserRoleVariant,
      resolveUserTypeVariant
    }
  },
}
</script>

<style>

</style>
