<template>
  <section>
    <b-card>
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="12"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <div class="p-1">
            <h4 class="mb-0">Reviews</h4>
            <div class="review mt-2" v-for="review in reviews" :key="review.id">
                <div class="d-flex flex-row comment-user">
                  <b-avatar
                    :src="review.image"
                    :text="review.fullName"
                    :variant="`light-primary`"
                    size="40px"
                    rounded
                  />
                    <div class="ml-2">
                        <div class="d-flex flex-row align-items-center">
                          <span class="name font-weight-bold">{{review.fullName}}</span>
                          <span>&nbsp;-&nbsp;</span>
                          <span class="date">{{review.date | dateFormat('MMMM DD YYYY') }}</span>
                        </div>
                        <ul class="unstyled-list list-inline">
                          <li
                            v-for="star in 5"
                            :key="star"
                            class="ratings-list-item"
                            :class="{'ml-25': star-1}"
                          >
                            <feather-icon
                              icon="StarIcon"
                              size="16"
                              :class="[{'fill-current': star <= review.rating}, star <= review.rating ? 'text-warning' : 'text-muted']"
                            />
                          </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <p class="comment-text">{{review.comment}}</p>
                </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,

    vSelect,
  },
  props: {
    reviews: {
      type: Array,
      required: true,
    },
  },
}
</script>