<template>
  <b-card
    no-body
    class="border-primary"
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">
        <span v-if="userData.type !== 'Seeker'">Rate: ${{ userData.rate }}</span>
      </h5>
      <b-badge variant="light-primary">
        {{ userData.type }}
      </b-badge>
    </b-card-header>

    <b-card-body class="mt-1">
        <table class="mt-xl-0 w-100">
          <!-- <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.status }}
            </td>
          </tr> -->
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Country</span>
            </th>
            <td class="pb-50">
              {{ userData.country }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="PlayIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Language</span>
            </th>
            <td class="pb-50">
              {{ userData.language }}
            </td>
          </tr>
        </table>
      <div v-if="userID && userID !== userData.uid">
        <div>
          <b-overlay
            :show="busy"
            rounded
            opacity="0.4"
            spinner-small
            spinner-variant="primary"
          >
            <b-button
              v-on:click="requestChat(userData)"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              class="mt-2 mb-2"
            >
              Chat
            </b-button>
          </b-overlay>
        </div>
        <div v-if="userData.type !== 'Seeker'">
          <hr />
          <div v-if="selectedDate">
            <div class="demo-inline-spacing">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon"
                @click="backToAvailability"
              >
                <feather-icon icon="ArrowLeftCircleIcon" />
              </b-button>
              <h4 class="mt-2">
                <span>Pick a Time</span>
              </h4>
            </div>
            <div class="text-center mt-1" v-if="selectedDate">
              {{ selectedDate | dateFormat('dddd, MMM D, YYYY')}}
            </div>
            <div class="mt-2">
              <validation-provider
                #default="{ errors }"
                name="duration"
                rules="required"
              >
                <b-form-group
                  label="Meeting Duration"
                  label-for="duration"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    id="duration"
                    v-model="selectedDuration"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="duration"
                    :selectable="option => ! option.text.includes('select_value')"
                    label="text"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
            <div v-show="loadBooking" class="text-center">
              <b-spinner variant="primary" label="Text Centered"></b-spinner><br/><br/>
              <strong>Loading Availability...</strong>
            </div>
            <div class="d-flex flex-wrap justify-content-center">
              <div 
                class="mt-2 mr-2" 
                v-for="(interval,index) in intervals"
                :key="index"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="onTimeSelect(interval)"
                >
                  {{interval}}
                </b-button>
              </div>
            </div>
          </div>
          <div v-else>
            <div v-if="userData.availability">
              <h4 class="mb-1">
                <span>Availability</span>
              </h4>
              <b-calendar
                block
                :hide-header=true
                :min="min"
                :max="max"
                :date-info-fn="dateClass"
                :show-decade-nav=false
                :date-disabled-fn="dateDisabled"
                @context="onDateSelect"
                locale="en"
              />
            </div>
            <div v-else class="d-flex flex-wrap justify-content-center">
              No Availability
            </div>
          </div>
        </div>
      </div>
    </b-card-body>
    <b-modal
      id="review-modal"
      centered
      title="Write Review"
    >
      <b-card
        no-body
        class="card-developer-meetup"
      >
        <div class="bg-light-primary rounded-top text-center">
          <b-img
            :src="require('@/assets/images/illustration/api.svg')"
            alt="Review Image"
            height="170"
          />
        </div>
        <b-card-body>
          <!-- metting header -->
          <div class="d-flex align-items-center mb-2">
            <div class="my-auto">
              <b-card-title class="mb-25">
                Write a review for {{userData.fullName}}
              </b-card-title>
            </div>
          </div>

          <!--/ metting header -->
          <ValidationObserver ref='reviewForm'>
            <form @submit="handleSubmit($event, onSubmit)">
              <validation-provider
                #default="{ errors }"
                name="rating"
                rules="required|integer"
              >
                <b-form-group
                  label-for="ratings"
                  :state="errors.length > 0 ? false:null"
                >
                  <b-form-rating
                    v-model="newRatingValue"
                    show-value
                    inline
                    no-border
                    :state="newRatingValue > 0"
                    variant="primary"
                    class="d-block mb-2"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <!-- <validation-provider
                #default="{ errors }"
                name="industries"
                rules="required"
              >
                <b-form-group
                  label="Industries"
                  label-for="industries"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    id="industry"
                    multiple
                    v-model="selectedIndustries"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="industries"
                    :selectable="option => ! option.text.includes('select_value')"
                    label="text"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider> -->

              <validation-provider
                #default="{ errors }"
                name="review"
                rules="required|min:150"
              >
                <b-form-group
                  label="Review"
                  label-for="Review"
                  :state="errors.length > 0 ? false:null"
                >
                <b-form-textarea
                  id="textarea-state"
                  v-model="newRatingDescription"
                  
                  placeholder="Write a review (min 150 characters)"
                  rows="3"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
              </validation-provider>
            </form>
          </ValidationObserver>
        </b-card-body>
      </b-card>
    
      <template #modal-footer>
        <div class="w-100">
          <b-overlay
            :show="busy"
            rounded
            opacity="0.4"
            spinner-small
            spinner-variant="primary"
            class="d-inline-block float-right"
            @hidden="onHidden"
          >
            <b-button
              ref="reviewButton"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              size="md"
              @click="submitReview"
            >
              Submit Review
            </b-button>
          </b-overlay>
        </div>
      </template>
    </b-modal>
    <b-modal
      id="booking-expert-modal"
      centered
      title="Confirm Booking"
    >
      <b-card
        no-body
        class="card-developer-meetup"
      >
        <b-overlay
          :show="show"
          variant="transparent"
          opacity='0.8'
          blur="2px"
          rounded="sm"
        >
          <div class="bg-light-primary rounded-top text-center">
            <b-img
              :src="require('@/assets/images/illustration/email.svg')"
              alt="Meeting Pic"
              height="170"
            />
          </div>
          <b-card-body v-if="showPayment">
            <stripe-element-payment
              ref="paymentRef"
              :pk="publishableKey"
              :elements-options="elementsOptions"
              :confirm-params="confirmParams"
              :error="setStripeErrors"
            />
          </b-card-body>
          <b-card-body v-else>
            <!-- metting header -->
            <div class="meetup-header d-flex align-items-center">
              <div class="meetup-day" v-if="selectedDate">
                <h6 class="mb-0">
                  {{ selectedDate | dateFormat('dddd')}}
                </h6>
                <h3 class="mb-0">
                  {{ selectedDate | dateFormat('D')}}
                </h3>
              </div>
              <div class="my-auto">
                <b-card-title class="mb-25">
                  Meeting with {{userData.fullName}}
                </b-card-title>
                <!-- <b-card-text class="mb-0">
                  Chat with an expert
                </b-card-text> -->
              </div>
            </div>
            <!--/ metting header -->

            <!-- media -->
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar
                  rounded
                  variant="light-primary"
                  size="34"
                >
                  <feather-icon
                    icon="CalendarIcon"
                    size="18"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body v-if="selectedDate">
                <h5 class="mb-0">
                  {{ selectedDate | dateFormat('dddd, MMM D, YYYY')}} <br />{{ selectedTime }} to {{ endTime }}
                </h5>
              </b-media-body>
            </b-media>

            <b-media no-body v-if="userData.googleCalendar">
              <b-media-aside class="mr-1">
                <b-avatar
                  rounded
                  variant="light-success"
                  size="34"
                >
                  <feather-icon
                    icon="MapPinIcon"
                    size="18"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h5 class="mb-0.5">
                  {{userData.fullName.split(' ')[0]}} has Google Calendar/Meet set up.
                </h5>
                <small class="text-muted">Google meeting link will be sent after booking</small>
              </b-media-body>
            </b-media>
            
            <b-media no-body v-else>
              <b-media-aside class="mr-1">
                <b-avatar
                  rounded
                  variant="light-danger"
                  size="34"
                >
                  <feather-icon
                    icon="MapPinIcon"
                    size="18"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h5 class="mb-0.5">
                  {{userData.fullName.split(' ')[0]}} is not setup to use Google Calendar/Meet.
                </h5>
                <small class="text-muted">{{userData.fullName.split(' ')[0]}} will follow up on Hustleforge chat with details of how to meet (Google, Facetime, Zoom etc).</small>
              </b-media-body>
            </b-media>

            <b-media no-body v-if="userData.stripeComplete">
              <b-media-aside class="mr-1">
                <b-avatar
                  rounded
                  variant="light-success"
                  size="34"
                >
                  <feather-icon
                    icon="CreditCardIcon"
                    size="18"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h5 class="mb-0.5">
                  {{userData.fullName.split(' ')[0]}} has payments set up.
                </h5>
                <small class="text-muted">Payment will be collected at the time of the booking</small>
              </b-media-body>
            </b-media>
            
            <b-media no-body v-else>
              <b-media-aside class="mr-1">
                <b-avatar
                  rounded
                  variant="light-danger"
                  size="34"
                >
                  <feather-icon
                    icon="CreditCardIcon"
                    size="18"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h5 class="mb-0.5">
                  {{userData.fullName.split(' ')[0]}} is not setup to use collect payments.
                </h5>
                <small class="text-muted">{{userData.fullName.split(' ')[0]}} will follow up on Hustleforge chat with details of how to pay. Ensure to use your best judgment before booking as Hustleforge cannot issue a refund for this booking.</small>
              </b-media-body>
            </b-media>

            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar
                  rounded
                  variant="light-primary"
                  size="34"
                >
                  <feather-icon
                    icon="CreditCardIcon"
                    size="18"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h5 class="mb-0.5">
                  Cost: ${{amount / 100}}
                </h5>
                <small class="text-muted">Rate ${{userData.rate/2}} per 30 mins</small>
              </b-media-body>
            </b-media>
          </b-card-body>
        </b-overlay>
      </b-card>
    
      <template #modal-footer>
        <div class="w-100">
          <b-overlay
            :show="busy"
            rounded
            opacity="0.4"
            spinner-small
            spinner-variant="primary"
            class="d-inline-block float-right"
            @hidden="onHidden"
          >
            <b-button
              v-if="showPayment"
              ref="bookButton"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              size="lg"
              @click="submitBooking"
            >
              Pay ${{amount / 100}}
            </b-button>
            <b-button
              v-else
              ref="bookButton"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              size="lg"
              @click="submitBooking"
            >
              Book
            </b-button>
          </b-overlay>
        </div>
      </template>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, 
  BCardHeader, 
  BCardBody, 
  BBadge, 
  BButton, 
  BCalendar, 
  BModal, 
  BMedia, 
  BMediaBody, 
  BAvatar, 
  BMediaAside, 
  BCardText,
  BCardTitle,
  BImg,
  BFormGroup,
  BFormInvalidFeedback,
  BOverlay,
  BSpinner,
  BFormRating,
  BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { StripeElementPayment } from '@vue-stripe/vue-stripe';
import Ripple from 'vue-ripple-directive'
import moment from 'moment-timezone'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getFirestore, doc, getDoc} from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions()
const db = getFirestore()
const auth = getAuth()
// Set default time zone to "America/Los_Angeles"
const defaultSystemTimeZone = "America/Los_Angeles"

export default {
  directives: {
    Ripple,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
    BCalendar,
    BModal,
    BMedia,
    BAvatar,
    BMediaBody,
    BMediaAside,
    BCardText,
    BCardTitle,
    BFormGroup,
    BImg,
    StripeElementPayment,
    vSelect,
    BFormInvalidFeedback,
    BOverlay,
    BSpinner,
    BFormRating,
    BFormTextarea,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const minDate = new Date(today)
    minDate.setMonth(minDate.getMonth())
    minDate.setDate( now.getDate()+1)
    const maxDate = new Date(today)
    maxDate.setMonth(maxDate.getMonth()+2) // Set bookings only two months out
    maxDate.setDate( now.getDate()+1)
    return {
      currentUser: null,
      userID: null,
      min: minDate,
      max: maxDate,
      selectedDate: null,
      selectedTime: null,
      selectedDuration: {text:'1 hour', value:60},
      endTime: null, 
      intervals: [],
      amount: 0,
      showPayment: false,
      publishableKey: this.$stripeKey,
      elementsOptions: {
        appearance: {
          variables: {
            colorPrimary: '#7367f0',
          },
        }, // appearance options
      },
      confirmParams: {
        return_url: 'http://'+window.location.host+this.$route.path, // success url
      },
      duration:[
        {text:'30 mins', value:30 },
        {text:'1 hour', value:60 },{text:'1 hour 30 mins', value:90},
        {text:'2 hours', value:120},{text:'2 hours 30 mins', value:150},
        {text:'3 hours', value:180},{text:'3 hours 30 mins', value:210},
        {text:'4 hours', value:240},{text:'4 hours 30 mins', value:270},
        {text:'5 hours', value:285},{text:'5 hours 30 mins', value:315},
      ],
      required,
      show: true,
      loadBooking: true,
      busy: false,
      newRatingValue: null,
      newRatingDescription: '',
      selectedIndustries: [],
      industries: [
        {text:'Accounting', value:'Accounting'},
        {text:'Airlines/Aviation', value:'Airlines/Aviation'},
        {text:'Alternative Dispute Resolution', value:'Alternative Dispute Resolution'},
        {text:'Alternative Medicine', value:'Alternative Medicine'},
        {text:'Animation', value:'Animation'},
        {text:'Apparel & Fashion', value:'Apparel & Fashion'},
        {text:'Architecture & Planning', value:'Architecture & Planning'},
        {text:'Arts & Crafts', value:'Arts & Crafts'},
        {text:'Automotive', value:'Automotive'},
        {text:'Aviation & Aerospace', value:'Aviation & Aerospace'},
        {text:'Banking', value:'Banking'},
        {text:'Biotechnology', value:'Biotechnology'},
        {text:'Broadcast Media', value:'Broadcast Media'},
        {text:'Building Materials', value:'Building Materials'},
        {text:'Business Supplies & Equipment', value:'Business Supplies & Equipment'},
        {text:'Capital Markets', value:'Capital Markets'},
        {text:'Chemicals', value:'Chemicals'},
        {text:'Civil Engineering', value:'Civil Engineering'},
        {text:'Commercial Real Estate', value:'Commercial Real Estate'},
        {text:'Computer & Network Security', value:'Computer & Network Security'},
        {text:'Computer Games', value:'Computer Games'},
        {text:'Computer Hardware', value:'Computer Hardware'},
        {text:'Computer Networking', value:'Computer Networking'},
        {text:'Computer Software', value:'Computer Software'},
        {text:'Construction', value:'Construction'},
        {text:'Consumer Electronics', value:'Consumer Electronics'},
        {text:'Consumer Goods', value:'Consumer Goods'},
        {text:'Consumer Services', value:'Consumer Services'},
        {text:'Cosmetics', value:'Cosmetics'},
        {text:'Dairy', value:'Dairy'},
        {text:'Defense & Space', value:'Defense & Space'},
        {text:'Design', value:'Design'},
        {text:'E-Learning', value:'E-Learning'},
        {text:'Education Management', value:'Education Management'},
        {text:'Electrical & Electronic Manufacturing', value:'Electrical & Electronic Manufacturing'},
        {text:'Entertainment', value:'Entertainment'},
        {text:'Environmental Services', value:'Environmental Services'},
        {text:'Events Services', value:'Events Services'},
        {text:'Executive Office', value:'Executive Office'},
        {text:'Facilities Services', value:'Facilities Services'},
        {text:'Farming', value:'Farming'},
        {text:'Financial Services', value:'Financial Services'},
        {text:'Fine Art', value:'Fine Art'},
        {text:'Fishery', value:'Fishery'},
        {text:'Food & Beverages', value:'Food & Beverages'},
        {text:'Food Production', value:'Food Production'},
        {text:'Fundraising', value:'Fundraising'},
        {text:'Furniture', value:'Furniture'},
        {text:'Gambling & Casinos', value:'Gambling & Casinos'},
        {text:'Glass, Ceramics & Concrete', value:'Glass, Ceramics & Concrete'},
        {text:'Government Administration', value:'Government Administration'},
        {text:'Government Relations', value:'Government Relations'},
        {text:'Graphic Design', value:'Graphic Design'},
        {text:'Health, Wellness & Fitness', value:'Health, Wellness & Fitness'},
        {text:'Higher Education', value:'Higher Education'},
        {text:'Hospital & Health Care', value:'Hospital & Health Care'},
        {text:'Hospitality', value:'Hospitality'},
        {text:'Human Resources', value:'Human Resources'},
        {text:'Import & Export', value:'Import & Export'},
        {text:'Individual & Family Services', value:'Individual & Family Services'},
        {text:'Industrial Automation', value:'Industrial Automation'},
        {text:'Information Services', value:'Information Services'},
        {text:'Information Technology & Services', value:'Information Technology & Services'},
        {text:'Insurance', value:'Insurance'},
        {text:'International Affairs', value:'International Affairs'},
        {text:'International Trade and Development', value:'International Trade and Development '},
        {text:'Internet', value:'Internet'},
        {text:'Investment Banking', value:'Investment Banking'},
        {text:'Judiciary', value:'Judiciary'},
        {text:'Law Enforcement', value:'Law Enforcement'},
        {text:'Law Practice', value:'Law Practice'},
        {text:'Legal Services', value:'Legal Services'},
        {text:'Legislative Office', value:'Legislative Office'},
        {text:'Leisure, Travel & Tourism', value:'Leisure, Travel & Tourism'},
        {text:'Libraries', value:'Libraries'},
        {text:'Logistics & Supply Chain', value:'Logistics & Supply Chain'},
        {text:'Luxury Goods & Jewelry', value:'Luxury Goods & Jewelry'},
        {text:'Machinery', value:'Machinery'},
        {text:'Management Consulting', value:'Management Consulting'},
        {text:'Maritime', value:'Maritime'},
        {text:'Market Research', value:'Market Research'},
        {text:'Marketing & Advertising', value:'Marketing & Advertising'},
        {text:'Mechanical or Industrial Engineering', value:'Mechanical or Industrial Engineering'},
        {text:'Media Production', value:'Media Production'},
        {text:'Medical Device', value:'Medical Device'},
        {text:'Medical Practice', value:'Medical Practice'},
        {text:'Mental Health Care', value:'Mental Health Care'},
        {text:'Military', value:'Military'},
        {text:'Mining & Metals', value:'Mining & Metals'},
        {text:'Mobile Games', value:'Mobile Games'},
        {text:'Motion Pictures & FIlm', value:'Motion Pictures & FIlm'},
        {text:'Museums & Institutions', value:'Museums & Institutions'},
        {text:'Music', value:'Music'},
        {text:'Nanotechnology', value:'Nanotechnology'},
        {text:'Newspapers', value:'Newspapers'},
        {text:'Non-profit Organization Management', value:'Non-profit Organization Management'},
        {text:'Oil & Energy', value:'Oil & Energy'},
        {text:'Online Media', value:'Online Media'},
        {text:'Outsourcing/Offshoring', value:'Outsourcing/Offshoring'},
        {text:'Package/Freight Delivery', value:'Package/Freight Delivery'},
        {text:'Packaging & Containers', value:'Packaging & Containers'},
        {text:'Paper & Forest Products', value:'Paper & Forest Products'},
        {text:'Performing Arts', value:'Performing Arts'},
        {text:'Pharmaceuticals', value:'Pharmaceuticals'},
        {text:'Philanthropy', value:'Philanthropy'},
        {text:'Photography', value:'Photography'},
        {text:'Plastics', value:'Plastics'},
        {text:'Political Organization', value:'Political Organization'},
        {text:'Primary/Secondary Education', value:'Primary/Secondary Education'},
        {text:'Printing', value:'Printing'},
        {text:'Professional Training & Coaching', value:'Professional Training & Coaching'},
        {text:'Program Development', value:'Program Development'},
        {text:'Public Policy', value:'Public Policy'},
        {text:'Public Relations & Communications', value:'Public Relations & Communications'},
        {text:'Public Safety', value:'Public Safety'},
        {text:'Publishing', value:'Publishing'},
        {text:'Railroad Manufacture', value:'Railroad Manufacture'},
        {text:'Ranching', value:'Ranching'},
        {text:'Real Estate', value:'Real Estate'},
        {text:'Recreational Facilities & Services', value:'Recreational Facilities & Services'},
        {text:'Religious Institution', value:'Religious Institution'},
        {text:'Renewables & Environment', value:'Renewables & Environment'},
        {text:'Research', value:'Research'},
        {text:'Restaurants', value:'Restaurants'},
        {text:'Retail', value:'Retail'},
        {text:'Security & Investigations', value:'Security & Investigations'},
        {text:'Semiconductors', value:'Semiconductors'},
        {text:'Shipbuilding', value:'Shipbuilding'},
        {text:'Sporting Goods', value:'Sporting Goods'},
        {text:'Sports', value:'Sports'},
        {text:'Staffing & Recruiting', value:'Staffing & Recruiting'},
        {text:'Supermarkets', value:'Supermarkets'},
        {text:'Telecommunications', value:'Telecommunications'},
        {text:'Textiles', value:'Textiles'},
        {text:'Think Tanks', value:'Think Tanks'},
        {text:'Tobacco', value:'Tobacco'},
        {text:'Translation & Localization', value:'Translation & Localization'},
        {text:'Transportation/Trucking/Railroad', value:'Transportation/Trucking/Railroad'},
        {text:'Utilities', value:'Utilities'},
        {text:'Venture Capital & Private Equity', value:'Venture Capital & Private Equity'},
        {text:'Veterinary', value:'Veterinary'},
        {text:'Warehousing', value:'Warehousing'},
        {text:'Wine & Spirits', value:'Wine & Spirits'},
        {text:'Wireless', value:'Wireless'},
        {text:'Writing & Editing', value:'Writing & Editing'},
      ],
    }
  },
  mounted () {
    this.userID = (auth && auth.currentUser)?auth.currentUser.uid:null
    this.currentUser = JSON.parse(localStorage.getItem('userData'))
    
    // Set default timezone
    if(this.currentUser){
      if(!this.currentUser.defaultTimeZone) this.currentUser.defaultTimeZone = "America/Los_Angeles"
      moment.tz.setDefault(this.currentUser.defaultTimeZone)
    }

    // Payment succeeded
    if(this.$route.query.redirect_status && this.$route.query.redirect_status === "succeeded"){
      const paymentIntent = this.$route.query.payment_intent;
      
      // Wait for the booking webhook to be updated
      setTimeout(() => {
        //this.$router.push({ name: 'apps-calendar'}).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Booking Complete`,
            icon: 'CalendarIcon',
            variant: 'success',
          },timeout: 7000
        })
      })
      //}, 1000)
    }
  },
  methods: {
    setStripeErrors(errors){
      console.log(errors)
    },
    async submitBooking() {
      // Book meeting and make a stripe payment
      if(this.showPayment){
        this.$refs.paymentRef.submit()
        // Work around to check for strip error before booking
        setTimeout( async() => {
          const stripeErorr = this.$refs.paymentRef.$el.querySelector('#stripe-payment-element-errors').innerHTML
          if(!stripeErorr){
             await this.bookExpertMeeting(false)
          }
          this.busy = false
        }, 250)
      }
      else if(this.userData.stripeComplete) this.showPayment = true
      else this.bookExpertMeeting(true)
    },
    async bookExpertMeeting(redirect){
      this.busy = true
      let bookingID = ''
      if(auth.currentUser.uid < this.userData.uid)
        bookingID = auth.currentUser.uid + this.userData.uid  
      else
        bookingID = this.userData.uid + auth.currentUser.uid

      const meetTime = moment(this.selectedTime, ["h:mm A"]) 
      const startDate = moment(new Date(this.selectedDate)) 
      const endDate = moment(new Date(this.selectedDate)) 
      startDate.set({hour: meetTime.hours(), minute: meetTime.minutes(), second: meetTime.seconds()})
      endDate.set({hour: meetTime.hours(), minute: meetTime.minutes(), second: meetTime.seconds()}).add(this.selectedDuration.value, 'minutes')
 
      const googleCalEnabled = (this.currentUser.googleToken)?true:false
      const bookExpertMeeting = httpsCallable(functions, 'bookMeeting')
      const bookingData = {bookingID: bookingID+startDate.valueOf(), requestor: auth.currentUser.displayName, requestorEmail:auth.currentUser.email, requestorName: this.currentUser.fullName, expert: this.userData.username, defaultTimeZone:this.currentUser.defaultTimeZone, googleCalendar:googleCalEnabled, startDate: moment.utc(startDate).format(), endDate: moment.utc(endDate).format(), date: this.selectedDate, duration: this.selectedDuration.value }
      console.log(bookingData)
      //const bookingResponse = await bookExpertMeeting({booking:bookingData})
      
      // Do we need to redirect
      if(!redirect) return

      this.busy = false
      // Check the resopnse
      if(bookingResponse && bookingResponse.data.success){
        this.$bvModal.hide('booking-expert-modal')
        this.$router.push({ name: 'apps-calendar'}).then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Booking Complete`,
              icon: 'CalendarIcon',
              variant: 'success',
            },timeout: 5000
          })
        })
      }else{
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Booking Error`,
            icon: 'CalendarIcon',
            variant: 'danger',
            text: (bookingResponse.message)?bookingResponse.message:'There was an error with your booking please try again.',
          },timeout: 5000
        })
      }
    },
    async generatePaymentIntent() {
      let bookingID = ''
      this.busy = true
      if(auth.currentUser.uid < this.userData.uid)
        bookingID = auth.currentUser.uid + this.userData.uid  
      else
        bookingID = this.userData.uid + auth.currentUser.uid

      const meetTime = moment(this.selectedTime, ["h:mm A"])
      const startDate = moment(new Date(this.selectedDate)) 
      startDate.set({hour: meetTime.hours(), minute: meetTime.minutes(), second: meetTime.seconds()})

      // Create Payment Intent
      const stripeCreatePaymentIntent = httpsCallable(functions, 'stripeCreatePaymentIntent')
      const paymentIntent = await stripeCreatePaymentIntent({duration: this.selectedDuration.value, bookingID: bookingID+startDate.valueOf(), startDate: moment.utc(startDate).format(), username: this.userData.username, requestor: auth.currentUser.displayName})
      this.busy = false

      // Check if we recived a Payment Intent
      if(paymentIntent && paymentIntent.data.success){
        this.elementsOptions.clientSecret = paymentIntent.data.intent.client_secret
        this.show = false
      }else{
        this.busy = false
        this.$bvModal.hide('booking-expert-modal')
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Booking Error`,
            icon: 'AlertCircleIcon',
            variant: 'danger',
            text: (paymentIntent.data.message)?paymentIntent.data.message:'There was an issue create a payment',
          },timeout: 5000
        })
      }
    },
    backToAvailability(){
      this.selectedDate = null
      this.selectedTime = null
      this.showPayment = false
      this.intervals = []
    },
    async onTimeSelect(interval){
      // Make sure we have a duration selected
      if(!this.selectedDuration){
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Booking Duration`,
            icon: 'CalendarIcon',
            variant: 'danger',
            text: 'Select a duration before selecting an availabile time.',
          },timeout: 5000
        })
        return
      }

      // Set the selecteed time and amout
      this.selectedTime = interval
      this.amount = (this.selectedDuration.value/60) * this.userData.rate * 100

      // Set meeting time in UI
      const userTimeZone = this.userData.defaultTimeZone   
      const meetEndTime = moment.tz(this.selectedTime, ["h:mm A"], userTimeZone).add(this.selectedDuration.value, 'minutes')
      this.endTime = meetEndTime.format("h:mm A")

      // Check to make sure we have Availability
      const availabilityError = this.checkAvailability()
      if(availabilityError){
        this.showPayment = false
        this.busy = false
        this.$bvModal.show('booking-expert-modal')
        if(this.userData.stripeComplete) await this.generatePaymentIntent()
        else this.show = false
      }else{
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Booking time not availabile`,
            icon: 'CalendarIcon',
            variant: 'danger',
            text: 'Check the meeting time and duration',
          },timeout: 5000
        })
      }
    },
    checkAvailability(){
      // Not sure I need this anymore 
      // const userTimeZone = this.userData.defaultTimeZone
      // const dayofWeek = this.selectedDate.getDay()
      // const intervals = this.userData.availability[dayofWeek].intervals
      // const meetStartTime = moment.tz(this.selectedTime, ["h:mm A"], userTimeZone)    
      // const meetEndTime = moment.tz(this.selectedTime, ["h:mm A"], userTimeZone).add(this.selectedDuration.value, 'minutes')
      // this.endTime = meetEndTime.format("h:mm A")

      // console.log(meetStartTime)
      // console.log(meetEndTime)

      // for(const interval of intervals){
      //   const startTime = moment.tz(interval.startTime, ["h:mm A"], userTimeZone)
      //   const endTime = moment.tz(interval.endTime, ["h:mm A"], userTimeZone)
      //   if( (meetStartTime.isBetween((startTime), moment(endTime)) || meetStartTime.isSame(startTime)) &&
      //       (meetEndTime.isBetween((startTime), moment(endTime)) || meetEndTime.isSame(endTime)))
      //    return true
      // }
      return true
    },
    async onDateSelect(c) {
      if(c.selectedDate){
        this.selectedDate = c.selectedDate
        const dayofWeek = this.selectedDate.getDay()
        const timeIntervals = this.userData.availability[dayofWeek].intervals
        const userTimeZone = this.userData.defaultTimeZone

        const bookingAvailability = httpsCallable(functions, 'getBookingAvailability')
        const bookingResponse = await bookingAvailability({selectedDate: moment.utc(this.selectedDate).format(), username:this.userData.username})
        console.log(bookingResponse)
        
        // Disable Loading
        this.loadBooking = false

        const bookingIntervals = (bookingResponse && bookingResponse.data.bookings)?bookingResponse.data.bookings:[]
        for ( const interval of timeIntervals){
          for (var m = moment.tz(interval.startTime, ["h:mm A"], userTimeZone); m.diff(moment.tz(interval.endTime, ["h:mm A"], userTimeZone), 'hour') < 0; m.add(30, 'minutes')) {
            const checkTime = m.tz(this.currentUser.defaultTimeZone).format("h:mm A")
            if(this.isAvailabile(bookingIntervals, checkTime)) this.intervals.push(checkTime)
          }
        }
      }
    },
    isAvailabile(intervals, checkTime){
      var available = true
      const meetTime = moment(checkTime, ["h:mm A"]) 
      for ( const interval of intervals){
        const startTime = moment(new Date(interval.start._seconds*1000))
        const endTime = moment(new Date(interval.end._seconds*1000))
        const newMeetTime = moment(new Date(interval.start._seconds*1000))
        newMeetTime.set({hour: meetTime.hours(), minute: meetTime.minutes(), second: meetTime.seconds()})
        if(newMeetTime.isBetween(startTime, endTime, 'minutes', '[)')){  
          available = false
        }
      }

      return available
    },
    dateClass(ymd, date) {
      return 'bg-primary';
    },
    dateDisabled(ymd, date) {
      if(!this.userData && !this.userData.availability) return false
      const weekday = date.getDay()
      let dayofWeek = 0
      for (const day of this.userData.availability) {
        if (day.value && dayofWeek === weekday){  
          return false
        }
        dayofWeek++
      }
      return true
    },
    writeReview(){
      this.$bvModal.show('review-modal')
    },
    submitReview(){
      this.$refs.reviewForm.validate().then(async(success) => {
        if (success) {
          this.busy = true
          const reviewedIndustries = null;//this.selectedIndustries.map(industry => industry.value)
          const writeExpertReview = httpsCallable(functions, 'writeExpertReview')
          const resp = await writeExpertReview({rateduser:this.userData.username, username:auth.currentUser.displayName, fullName:this.currentUser.fullName, email:this.currentUser.email, image:this.currentUser.image, comment:this.newRatingDescription.trim(), industries:reviewedIndustries, rating:this.newRatingValue })

          console.log(resp)
          if(resp && resp.data.success){
            this.$bvModal.hide('review-modal')
            
            // Clear inputs for review
            newRatingValue = null
            newRatingDescription = ''

            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Review Submitted`,
                icon: 'StarIcon',
                variant: 'success',
              },timeout: 5000
            })
          }else{
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'New Review',
                icon: 'StarIcon',
                variant: 'danger',
                text: 'There was an issue processing the review, please try agian.',
              },timeout: 5000
            })
          }
          this.busy = false
        }
      })
    },
    async requestChat() {

      // Create an Chat ID of each user combination
      let chatID = "";
      if (this.userID < this.userData.uid) {
        chatID = this.userID + this.userData.uid;
      } else {
        chatID = this.userData.uid + this.userID;
      }

      // Are they already connected
      const alraedyConnected = await getDoc(doc(db, "chats", chatID))
      if (!alraedyConnected.exists()){
        this.busy = true
        const connectUsers = httpsCallable(functions, 'connectUsers')
        const resp = await connectUsers({
          currentUserID: this.userID,
          currentUser: auth.currentUser.displayName,
          currentUserFullName: this.currentUser.fullName, 
          currentUserImage: this.currentUser.image, 
          currentUserTagline: this.currentUser.tagline, 
          currentUserType: this.currentUser.type, 
          connectedUserID: this.userData.uid,
          connectedUser: this.userData.username})
        this.busy = false
        //console.log(resp)
      }
      this.$router.push({ name: 'apps-chat-user', params: { id: this.userData.username }})
    },
    onHidden() {
      // Return focus to the button once hidden
      if(this.$refs.bookButton)this.$refs.bookButton.focus()
    },
  }
}
</script>
<style lang="scss">
.scroll {
    max-height: 300px;
    overflow-y: auto;
}
</style>
