<template>
  <section>
    <b-card>
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="12"
          class="align-items-center justify-content-start mb-1 mb-md-0"
        >
          <div class="p-1" v-if="userData.idea && userData.idea.length > 0">
            <h4 class="mb-0">Idea / Problem</h4>
            <div class="review mt-2">
              <p v-if="userID" class="comment-text">{{userData.idea}}</p>
              <div v-if="!userID">
                <p class="comment-text">{{userData.idea.substring(0,25)+".." }}</p>
                <b-button :to="{name:'auth-register'}" variant="primary" block class="float-right" >Signup to read more</b-button>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { getAuth } from "firebase/auth";
const auth = getAuth()

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,

    vSelect,
  },
  data() {
   return {
      userID: null,
   }
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  created: async function() {
    this.userID = (auth && auth.currentUser)?auth.currentUser.uid:null
  }
}
</script>